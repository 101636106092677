<template>
  <div :class="$style['worker-container']">
    <span :class="$style.title">{{ title }}</span>
    <slot name="action"></slot>
  </div>
</template>

<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
@Component({})
export default class WorkerTabTitle extends Vue {
  @Prop({ type: String, default: '' }) title;
  created() {}
  mounted() {}
}
</script>
<style lang="less" module>
.worker-container {
  width: 99%;
  height: 0.32rem;
  background: #223142;
  display: flex;
  margin: 0.16rem auto;
  line-height: 0.32rem;
  padding: 0 0.08rem;
  .title {
    font-size: 0.14rem;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    margin-right: 0.1rem;
  }
}
</style>
