<template>
  <div>
    <div :class="$style.search">
      <div :class="$style.itemWrap">
        <div :class="$style.item">
          <a-select
            v-model="queryParams.deviceId"
            :placeholder="$t('videoMonitor.selectDevice')"
            style="width: 200px;"
            allowClear
            @change="() => handleChange()"
          >
            <a-select-option
              v-for="item in deviceList"
              :key="item.deviceId"
              :value="item.deviceId"
            >
              {{ item.deviceName }}
            </a-select-option>
          </a-select>
        </div>
      </div>
    </div>
    <a-table
      :columns="columns"
      :data-source="weighingList"
      :pagination="pagination"
      :loading="loading"
      :scroll="{ x: '100%' }"
      :transformCellText="({ text }) => text || '-'"
      @change="handlePagination"
      :style="selectStyle(weighingList)"
    />
  </div>
</template>

<script>
import { Component, Vue } from 'vue-property-decorator';
import OssImage from '@/views/screen/components/oss-image.vue';
import { getWeighingRecord } from '@/services/screen/jiangsu/intelligent-weighbridge';
import store from '@/store';
import { deviceListById } from '@/services/device-manage/device';
import { deviceTypeEnum } from '@/enum/device';

@Component({
  components: {
    OssImage,
  },
})
export default class WeighingData extends Vue {
  get projectId() {
    return store.state.screen.projectId;
  }

  mounted() {
    this.getDeviceList();
    this.getWeighingList();
  }

  get columns() {
    return [
      {
        dataIndex: 'index',
        customRender: (text, row, index) => <span>{index + 1}</span>,
        title: this.$t('safety.index'),
        align: 'center',
        width: 80,
      },
      {
        align: 'left',
        title: '设备名称',
        dataIndex: 'deviceName',
        ellipsis: true,
      },
      // {
      //   align: 'left',
      //   title: '设备码',
      //   dataIndex: 'code',
      //   ellipsis: true,
      // },
      {
        align: 'left',
        title: '车牌号',
        dataIndex: 'plateNumber',
        ellipsis: true,
      },
      {
        align: 'left',
        title: '进入时间',
        dataIndex: 'inTime',
        width: 180,
        ellipsis: true,
        customRender: text => this.$moment(text).format('YYYY-MM-DD HH:mm:ss'),
      },
      {
        align: 'left',
        title: '进入重量(KG)',
        dataIndex: 'inWeight',
        ellipsis: true,
        customRender: text => text,
      },
      {
        align: 'left',
        title: '离开时间',
        dataIndex: 'outTime',
        width: 180,
        ellipsis: true,
        customRender: text => this.$moment(text).format('YYYY-MM-DD HH:mm:ss'),
      },
      {
        align: 'left',
        title: '离开重量(KG)',
        dataIndex: 'outWeight',
        ellipsis: true,
      },
      {
        align: 'left',
        title: '毛重量(KG)',
        dataIndex: 'useWeight',
        ellipsis: true,
      },
      {
        align: 'left',
        title: '过磅图片',
        width: 300,
        dataIndex: 'imgs',
        customRender: text => (text.length ? <oss-image ossPath={text} /> : ''),
      },
    ];
  }
  queryParams = {
    days: 'THIRTY_DAY',
    current: 1,
    size: 10,
    projectId: this.projectId,
    deviceId: undefined,
  };
  pagination = {
    current: 1,
    pageSize: 10,
    total: 0,
    showSizeChanger: true,
    pageSizeOptions: ['10', '20', '30', '40'],
    size: 'small',
    showTotal: total => `${total} ${this.$t('common.total')}`,
  };
  // 表格固定高度
  selectStyle(data) {
    return data.length < 10 ? 'height: 5.5rem;' : 'min-height: 5.5rem;';
  }

  loading = false;
  weighingList = [];
  async getWeighingList() {
    try {
      this.loading = true;
      const { records, total } = await getWeighingRecord(this.queryParams);
      this.weighingList = records;
      this.pagination.total = total;
    } finally {
      this.loading = false;
    }
  }
  handlePagination(data) {
    this.pagination = data;
    this.queryParams.current = data.current;
    this.queryParams.size = data.pageSize;
    this.getWeighingList();
  }
  handleChange() {
    this.queryParams.current = 1;
    this.getWeighingList();
  }

  // 获取设备列表
  deviceList = [];
  async getDeviceList() {
    try {
      this.deviceList = await deviceListById(
        this.projectId,
        deviceTypeEnum.WB.deviceGroup,
      );
    } catch {
      this.deviceList = [];
      return false;
    }
  }
}
</script>

<style lang="less" module>
.search {
  display: flex;
  justify-content: space-between;
  .itemWrap {
    display: flex;
    margin-bottom: 0.2rem;
  }
  .item {
    margin-right: 0.15rem;
  }
}
</style>
