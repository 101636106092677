const ThemeData = {
  tint: {
    font: '#fff',
    mainBg: '#ffffff',
    border: '#eeeeee',
    fontInfo: '#999999',
    pieBorder: '#ffffff',
    mapBorderColor: '#ffffff',
    mapAreaColor: '#efefef',
  },
  dark: {
    font: '#fff',
    tooltipFont: '#ffffff',
    mainBg: 'rgba(48, 79, 108, 0.6)',
    border: '#5e6067',
    fontInfo: '#666666',
    pieBorder: '#353535',
    mapBorderColor: '#313131',
    mapAreaColor: '#444444',
  },
  purple: {
    primary: '#9743fe',
    primary60: '#c18efe',
    primary50: '#cba1ff',
    primary_fade_30: 'rgba(151, 67, 254, 0.3)',
  },
  green: {
    primary: '#41c400',
    primary60: '#8ddc66',
    primary50: '#a0e280',
    primary_fade_30: 'rgba(65, 196, 0, 0.3)',
  },
  cyan: {
    primary: '#00b3b3',
    primary60: '#66d1d1',
    primary50: '#80d9d9',
    primary_fade_30: 'rgba(0, 179, 179, 0.3)',
  },
  red: {
    primary: '#ff333d',
    primary60: '#ff858b',
    primary50: '#ff999e',
    primary_fade_30: 'rgba(255, 51, 61, 0.3)',
  },
  blue: {
    primary: '#4771ff',
    primary60: '#91aaff',
    primary50: '#a3b8ff',
    primary_fade_30: 'rgba(71, 113, 255, 0.3)',
  },
  orange: {
    primary: '#f99603',
    primary60: '#fbc068',
    primary50: '#fccb81',
    primary_fade_30: 'rgba(249, 150, 3, 0.3)',
  },
  grey: {
    primary: '#222222',
    primary60: '#7a7a7a',
    primary50: '#919191',
    primary_fade_30: 'rgba(136, 136, 136, 0.3)',
  },
  volcanic: {
    primary: '#fa541c',
    primary60: '#ff9c79',
    primary50: '#ffad90',
    primary_fade_30: 'rgba(250, 84, 28, 0.3)',
  },
  azure: {
    primary: '#04a9f3',
    primary60: '#68cbf8',
    primary50: '#82d4f9',
    primary_fade_30: 'rgba(4, 169, 243, 0.3)',
  },
};

export default ThemeData;
