<template>
  <div :class="$style.record">
    <div :class="$style.list">
      <div
        :class="$style.item"
        v-for="(item, index) in weighingAnalyseList"
        :key="index"
        @click="openWeighingDetail(item)"
      >
        <div :class="$style.left">
          <span
            >送货单总重量(kg):
            {{
              item.manifestTotalWeight !== -1 ? item.manifestTotalWeight : '--'
            }}</span
          >
          <span v-if="item.weighingNetWeight !== -1 && item.weighingNetWeight"
            >过磅净重量(kg):
            {{
              item.weighingNetWeight !== -1 ? item.weighingNetWeight : '--'
            }}</span
          >
          <span v-else>暂无过磅记录</span>
        </div>
        <div :class="$style.center">
          <span
            >到货日期:
            {{ $moment(item.manifestTime).format('YYYY-MM-DD') }}</span
          >
          <span v-if="item.weighingNetWeight !== -1 && item.weighingNetWeight"
            >偏差率:
            {{
              item.deviationRate !== -1 ? item.deviationRate + '%' : '--'
            }}</span
          >
        </div>
        <div :class="$style.right">
          <template v-if="item.manifestPictures.length">
            <div
              :class="$style.img"
              v-for="(imgItem, index) in item.manifestPictures"
              :key="index"
            >
              <OssImage
                basePath="/oss/oss"
                :ossPath="imgItem.value"
                :class="$style.weighingImg"
              />
            </div>
          </template>
          <template v-else>
            <div :class="$style.img">
              <EmptyContent :class="$style.emptyImg" />
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Component, Vue } from 'vue-property-decorator';
import { createModal } from '@triascloud/x-components';
import DeliveryData from './delivery-data.vue';
import WeighingDetails from './weighing-details.vue';
import { PublicSize } from '@/views/green-construction/components/utils';
import { getWeighingAnalyse } from '@/services/screen/jiangsu/intelligent-weighbridge';
import OssImage from '@/views/screen/components/oss-image.vue';

@Component({
  components: {
    OssImage,
  },
})
export default class IwWeighingRecord extends Vue {
  get projectId() {
    return this.$store.state.screen.projectId;
  }
  mounted() {
    this.getWeighingAnalyse();
  }
  weighingAnalyseList = [];
  async getWeighingAnalyse() {
    const params = {
      current: 1,
      projectId: this.projectId,
      size: 5,
    };
    const { records } = await getWeighingAnalyse(params);
    this.weighingAnalyseList = records;
  }
  openDetailModal() {
    createModal(() => <DeliveryData />, {
      title: '送货单过磅数据',
      width: PublicSize.LargeLogModalSize,
      closable: true,
      maskClosable: false,
      wrapClassName: 'screen-modal',
    });
  }
  openWeighingDetail(record = {}) {
    createModal(() => <WeighingDetails record={record} />, {
      title: '送货/过磅详情',
      width: 900,
      closable: true,
      maskClosable: false,
      wrapClassName: 'screen-modal',
    });
  }
}
</script>
<style lang="less" module>
.record {
  display: flex;
  padding: 0.08rem;
  .list {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .item {
      width: 100%;
      height: 0.8rem;
      margin-bottom: 0.1rem;
      padding-left: 0.2rem;
      display: flex;
      align-items: center;
      background-color: var(--screen-list-bg);
      color: var(--screen-font-4);
      .left,
      .center {
        height: 100%;
        display: flex;
        flex-direction: column;
        font-size: 0.14rem;
        span {
          margin-top: 0.1rem;
        }
      }
      .left {
      }
      .center {
        margin-left: 0.5rem;
      }
      .right {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        width: 35%;
        height: 100%;
        overflow-y: auto;
        margin-left: auto;
        column-gap: 0.15rem;
        .img {
          width: 0.64rem;
          height: 0.64rem;
        }
        .weighingImg {
          img {
            height: 100%;
            width: 100%;
          }
        }
        .emptyImg {
          :global {
            .x-empty-content--image {
              background-size: contain !important;
            }
          }
        }
      }
    }
  }
}
</style>
