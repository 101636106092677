<template>
  <div ref="lineChart" class="h-full w-full overflow-hidden"></div>
</template>

<script>
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import * as echarts from 'echarts';
import EmptyContent from '@/components/empty-content/empty-content.vue';
import { Emitter } from '../components/screen-utils';
import { nowSize } from '@/utils/common';
import theme from '@/utils/theme/theme';
import { crossStorageModule } from '@/enum/store';
import moment from 'moment';

@Component({
  components: {
    EmptyContent,
  },
})
export default class LineChart extends Vue {
  @Prop({ type: String, default: '100%' }) chartWidth;
  @Prop({ type: String, default: '100%' }) chartHeight;
  @Prop({ type: Array, default: () => [] }) chartData;
  @Prop({ type: Array, default: () => [] }) unit;
  @Prop({ type: Object, default: () => {} }) interval;
  @Prop({ type: Boolean, default: true }) isSmooth;
  @Prop({ type: String, default: 'circle' }) symbol;
  @Prop({ type: String, default: 'day' }) xInterValType;
  @Prop({ type: String, default: '10%' }) chartBottom;
  @Prop({ type: String, default: '0' }) chartRight;
  @Prop({ type: String, default: '1%' }) chartLeft;
  /**
   * @Description 是否展示lagend
   */
  @Prop({ type: Boolean, default: false }) showLegend;
  @Prop({
    type: Array,
    default: () => [
      '#4FA2FF',
      '#00C56E',
      '#FFCC37',
      '#EF6666',
      '#0065C4',
      '#07B9B9',
      '#3BA272',
      '#EA7CCC',
      '#91CC75',
      '#FAC858',
    ],
  })
  colorArray;

  @crossStorageModule.State('skin') skin;
  @Watch('skin')
  changeSkin() {
    this.initChart();
  }

  @Watch('chartData', { immediate: true, deep: true })
  changeData(newVal) {
    if (newVal && newVal[0]?.data.length) {
      this.isNoData = false;
      this.$nextTick(() => {
        setTimeout(this.initChart, 100);
      });
    } else {
      if (this.myChart) {
        this.myChart.dispose();
        this.myChart = null;
      }
      this.isNoData = true;
    }
  }
  chartResize() {
    setTimeout(() => {
      const rect = this.$refs.lineChart.getBoundingClientRect();
      const opt = {
        width: rect.width,
        height: rect.height,
      };
      if (this.myChart) {
        this.myChart.resize(opt);
      }
    }, 100);
  }
  mounted() {
    Emitter.$on('fullscreen', this.chartResize);
  }
  beforeDestroy() {
    Emitter.$off('fullscreen', this.chartResize);
  }
  isNoData = true;
  myChart = null;
  initChart() {
    if (!this.myChart) {
      const el = this.$refs.lineChart;
      if (!el) return;
      this.myChart = echarts.init(el);
    }
    const length = this.chartData[0]?.data.length;
    if (length === 0) return;
    // const dayType = {
    //   minute: 60000,
    //   hour: 3600000,
    //   day: 86400000,
    // };
    // const minInterval = dayType[this.xInterValType];
    const dayObj = {
      minute: 'HH:mm',
      hour: 'H时',
      day: 'MM-DD',
      month: 'YYYY-MM',
      year: 'YYYY',
    };
    const s = dayObj[this.xInterValType];
    const xData = this.chartData[0].time;
    const themeMode = theme['dark'];
    const options = {
      color: this.colorArray,
      tooltip: {
        trigger: 'axis',
        appendToBody: true,
        formatter: params => this.toolTip(params),
        backgroundColor: themeMode.mainBg,
        textStyle: {
          color: themeMode.tooltipFont,
          fontSize: nowSize(14),
        },
        extraCssText: 'border-radius:5px;border-color: rgba(27, 44, 60, 1)',
        padding: [nowSize(10)],
        position: (pos, params, el, elRect, size) => {
          const obj = { top: 10 };
          obj[['left', 'right'][+(pos[0] < size.viewSize[0] / 2)]] = 30;
          return obj;
        },
      },
      grid: {
        left: this.chartLeft,
        right: this.chartRight,
        bottom: this.chartBottom,
        top: '5%',
        containLabel: true,
      },
      xAxis: {
        type: 'category',
        data: xData,
        // min: 'dataMin',
        // max: 'dataMax',
        // minInterval,
        axisTick: {
          alignWithLabel: true,
        },
        axisLabel: {
          alignWithLabel: true,
          showMinLabel: true,
          showMaxLabel: true,
          color: themeMode.font,
          ...this.interval,
          // 格式化横轴坐标时间刻度
          formatter: val =>
            moment(+val).isValid() ? moment(+val).format(s) : val,
        },
      },
      yAxis: {
        type: 'value',
        axisLabel: {
          color: themeMode.font,
        },
        splitLine: {
          lineStyle: {
            color: themeMode.border,
          },
        },
      },
      series: [],
    };
    // 可以在这里根据 showLegend 和 legendData 配置 series
    this.chartData[0].data.forEach(v => {
      const o = {
        name: v.name,
        smooth: this.isSmooth, // 平滑
        symbol: this.symbol, // 不展示圆点
        type: 'line',
        data: v.data,
      };
      options.series.push(o);
    });
    if (this.showLegend) {
      const legendData = this.chartData[0].data.map(v => v.name);
      options['legend'] = {
        type: 'scroll',
        icon: 'roundRect',
        bottom: 0,
        data: legendData,
        itemHeight: nowSize(10),
        itemWidth: nowSize(10),
        textStyle: {
          color: themeMode.font,
        },
        // pageTextStyle: {
        //   color: themeMode.font,
        // },
        pageTextStyle: {
          color: '#D8E1F3',
          fontSize: nowSize(16),
        },
        pageIconSize: nowSize(16),
      };
    }
    this.myChart.setOption(options, true);
  }
  toolTip(params) {
    let result = `<div class="event-chart-box"><div>`;
    params.forEach((item, idx) => {
      let unit = '';
      if (this.unit.length > 0) {
        this.unit.length === 1
          ? (unit = this.unit[0])
          : (unit = this.unit[idx]);
      } else if (Array.isArray(item.data)) {
        unit = item.data[2];
      } else if (item.data.unit) {
        unit = item.data.unit;
      }
      const dayType = {
        minute: 'YYYY-MM-DD HH:mm',
        hour: 'YYYY-MM-DD HH:mm',
        day: 'YYYY-MM-DD',
        month: 'YYYY-MM',
        year: 'YYYY',
      };
      const time = moment(+item.axisValue).isValid()
        ? moment(+item.axisValue).format(dayType[this.xInterValType])
        : item.axisValue;
      const str1 = `<div>
									${time}
								</div>`;

      let value = item.value + unit ?? '';
      if (item.data.format) {
        value = item.data.format(item.value);
      }
      const str2 = `
          			<div class="warn-label">
            			<span class="color-label" style="background-color: ${item.color}"></span>
									<span>
										${item.seriesName}：${value}
									</span>
          			</div>
        			`;
      if (idx === 0) {
        result += str1 + str2;
      } else {
        result += str2;
      }
    });
    return result + '</div></div>';
  }
}
</script>

<style lang="less" module></style>
