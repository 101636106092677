<template>
  <ul
    :class="[
      $style['base-data-container'],
      isShowBg ? $style['is-showBg'] : '',
    ]"
  >
    <li
      :class="[$style.item, isClick ? $style.click : '']"
      v-for="item in configData"
      :key="item.title"
      @click="showDialogHandle(item)"
    >
      <h3>{{ item.value }}</h3>
      <p :title="item.title" :class="$style.title">{{ item.title }}</p>
    </li>
  </ul>
</template>

<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
@Component({})
export default class BaseWorkerItems extends Vue {
  @Prop({
    type: Array,
    default: () => [],
    required: true,
  })
  configData;
  @Prop({
    type: Boolean,
    default: false,
  })
  isClick;
  showDialogHandle(item) {
    this.$emit('showDetailDialog', { ...item });
  }
  @Prop({
    type: Boolean,
    default: false,
  })
  isShowBg;
}
</script>
<style lang="less" module>
.base-data-container {
  padding: 0.06rem 0.6rem;
  opacity: 1;
  list-style: none;
  padding: 0;
  box-sizing: border-box;
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin: 0;
  align-items: center;
  width: 100%;
  height: 100%;
  &.is-showBg {
    background: linear-gradient(
      90deg,
      var(--screen-nav-bg) 9%,
      #0f3f52 43%,
      #11465c 60%,
      var(--screen-nav-bg) 100%
    );
  }
  .item {
    // width: 1rem;
    height: 0.88rem;
    // margin: 0.1rem 0 0 0.07rem;
    background: linear-gradient(
      270deg,
      rgba(76, 175, 255, 0) 0%,
      rgba(76, 175, 255, 0.08) 52%,
      rgba(76, 175, 255, 0) 100%
    );
    display: flex;
    align-items: center;
    flex-direction: column;
    // margin-right: 0.05rem;
    flex-shrink: 0;

    h3 {
      margin-top: 0.1rem;
      font-size: 0.24rem;
      font-family: 'Microsoft YaHei';
      font-weight: 700;
      color: #fcffff;
      text-shadow: 0px 0px 7px #1f69c8, 0px 0px 10px #7db6ff;
      padding-bottom: 0.08rem;
      min-width: 0.7rem;
      position: relative;
      &:after {
        position: absolute;
        content: '';
        background: url('~@/assets/images/theme/labor-icon.png') no-repeat
          center bottom;
        width: 0.7rem;
        height: 100%;
        background-size: 100%;
        left: 50%;
        transform: translateX(-0.35rem);
        bottom: 0;
      }
    }
    .title {
      margin: 0;
      font-size: 0.14rem;
      font-family: D-DIN;
      color: #d8e1f3;
      overflow: hidden;
      /*文本不会换行*/
      white-space: nowrap;
      /*当文本溢出包含元素时，以省略号表示超出的文本*/
      text-overflow: ellipsis;
    }
  }
  .click {
    cursor: pointer;
  }
}
</style>
