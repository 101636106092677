<template>
  <div>
    <!-- <div :class="$style.search">
      <div :class="$style.itemWrap">
        <div :class="$style.item">
          <a-select
            v-model="form.pkId"
            :placeholder="$t('videoMonitor.selectDevice')"
            style="width: 200px;"
            allowClear
            @change="() => handleChange()"
          >
            <a-select-option
              v-for="item in deviceList"
              :key="item.deviceId"
              :value="item.deviceId"
            >
              {{ item.deviceName }}
            </a-select-option>
          </a-select>
        </div>
      </div>
    </div> -->
    <a-table
      :columns="columns"
      :data-source="weighingAnalyseList"
      :pagination="pagination"
      :loading="loading"
      :scroll="{ x: '100%' }"
      :transformCellText="({ text }) => (text === -1 || !text ? '-' : text)"
      @change="handlePagination"
      :style="selectStyle(weighingAnalyseList)"
    >
      <template slot="action" slot-scope="record">
        <a @click="openWeighingDetail(record)" style="color: #37D7DB;">详情</a>
      </template>
    </a-table>
  </div>
</template>

<script>
import { Component, Vue } from 'vue-property-decorator';
import { getWeighingAnalyse } from '@/services/screen/jiangsu/intelligent-weighbridge';
import OssImage from '@/views/screen/components/oss-image.vue';
import store from '@/store';
import { createModal } from '@triascloud/x-components';
import WeighingDetails from './weighing-details.vue';

@Component({
  components: {
    OssImage,
  },
})
export default class DeliveryData extends Vue {
  get projectId() {
    return store.state.screen.projectId;
  }

  mounted() {
    this.getWeighingAnalyse();
  }

  get columns() {
    return [
      {
        dataIndex: 'index',
        customRender: (text, row, index) => <span>{index + 1}</span>,
        title: this.$t('safety.index'),
        align: 'center',
        width: 80,
      },
      {
        align: 'left',
        title: '送货车单号',
        dataIndex: 'logisticsNumber',
        ellipsis: true,
      },
      // {
      //   align: 'left',
      //   title: '项目名称',
      //   dataIndex: 'projectName',
      //   ellipsis: true,
      // },
      {
        align: 'left',
        title: '送货物料总重量(KG)',
        dataIndex: 'manifestTotalWeight',
        width: 200,
        ellipsis: true,
        customRender: text => (text < 0 ? '' : text),
      },
      {
        align: 'left',
        title: '到货日期',
        dataIndex: 'manifestTime',
        width: 180,
        ellipsis: true,
        customRender: text => this.$moment(text).format('YYYY-MM-DD'),
      },
      {
        align: 'left',
        title: '过磅净重(KG)',
        dataIndex: 'weighingNetWeight',
        ellipsis: true,
        customRender: text => (text < 0 ? '' : text),
      },
      {
        align: 'left',
        title: '到货偏差率',
        dataIndex: 'deviationRate',
        ellipsis: true,
        customRender: text => (text === -1 ? '' : text + '%'),
      },
      {
        align: 'left',
        title: '送货图片',
        width: 300,
        dataIndex: 'manifestPictures',
        customRender: text => {
          const imgList = text.map(item => item.value);
          return text.length ? (
            <oss-image basePath="/oss/oss" ossPath={imgList} />
          ) : (
            ''
          );
        },
      },
      {
        title: this.$t('common.operation'),
        width: 100,
        scopedSlots: { customRender: 'action' },
      },
    ];
  }
  queryParams = {
    current: 1,
    size: 10,
    projectId: this.projectId,
  };
  pagination = {
    current: 1,
    pageSize: 10,
    total: 0,
    showSizeChanger: true,
    pageSizeOptions: ['10', '20', '30', '40'],
    size: 'small',
    showTotal: total => `${total} ${this.$t('common.total')}`,
  };
  // 表格固定高度
  selectStyle(data) {
    return data.length < 10 ? 'height: 5.5rem;' : 'min-height: 5.5rem;';
  }

  loading = false;
  weighingAnalyseList = [];
  async getWeighingAnalyse() {
    try {
      this.loading = true;
      const { records, total } = await getWeighingAnalyse(this.queryParams);
      this.weighingAnalyseList = records;
      this.pagination.total = total;
    } finally {
      this.loading = false;
    }
  }
  handlePagination(data) {
    this.pagination = data;
    this.queryParams.current = data.current;
    this.queryParams.size = data.pageSize;
    this.getWeighingAnalyse();
  }
  openWeighingDetail(record = {}) {
    createModal(() => <WeighingDetails record={record} />, {
      title: '送货/过磅详情',
      width: 900,
      closable: true,
      maskClosable: false,
      wrapClassName: 'screen-modal',
    });
  }
}
</script>

<style lang="less" module>
.search {
  display: flex;
  justify-content: space-between;
  .itemWrap {
    display: flex;
    margin-bottom: 0.2rem;
  }
  .item {
    margin-right: 0.15rem;
  }
}
</style>
