<template>
  <div :class="$style.weighingDetails">
    <WorkerTitle title="送货单信息" :class="$style.header" />
    <div :class="$style.infoList">
      <div
        :class="$style.infoItem"
        v-for="item in deliveryNoteIfo"
        :key="item.name"
      >
        <span>{{ item.name }}</span>
        <span>{{ item.value }}</span>
      </div>
    </div>
    <div :class="$style.table">
      <div :class="$style.tableTitle">送货物料</div>
      <ScrollTable :columns="columns" :data="record.manifestMaterials" />
    </div>
    <div :class="$style.image">
      <div :class="$style.imgTitle">送货图片</div>
      <div :class="$style.imageList">
        <template v-if="record.manifestPictures.length">
          <OssImage
            basePath="/oss/oss"
            :ossPath="item.value"
            :class="$style.imageItem"
            v-for="item in record.manifestPictures"
            :key="item.text"
          />
        </template>
        <div style="color: var(--screen-font-3);" v-else>--</div>
      </div>
    </div>
    <WorkerTitle title="地磅数据" :class="$style.header" />
    <div :class="$style.infoList">
      <div
        :class="$style.infoItem"
        v-for="item in weighingInfo"
        :key="item.name"
      >
        <span>{{ item.name }}</span>
        <span>{{ item.value }}</span>
      </div>
    </div>
    <WorkerTitle title="物流信息" :class="$style.header" />
    <div :class="$style.infoList">
      <div :class="$style.infoItem">
        <span>司机名称</span>
        <span>{{ formatValue(record.driverName) }}</span>
      </div>
    </div>
    <WorkerTitle title="发货信息" :class="$style.header" />
    <div :class="$style.infoList">
      <div :class="$style.infoItem">
        <span>供应商联系人</span>
        <span>{{ formatValue(record.supplierLiaison) }}</span>
      </div>
    </div>
    <WorkerTitle title="收货信息" :class="$style.header" />
    <div :class="$style.infoList">
      <div :class="$style.infoItem">
        <span>收货方</span>
        <span>{{ formatValue(record.receiver) }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
import OssImage from '@/views/screen/components/oss-image.vue';
import store from '@/store';
import WorkerTitle from '@/views/screen/project/people-management/components/worker-tab-title.vue';
import ScrollTable from '@/views/screen/components/scroll-table.vue';

@Component({
  components: {
    OssImage,
    WorkerTitle,
    ScrollTable,
  },
})
export default class DeliveryData extends Vue {
  @Prop({ type: Object, required: true, default: () => {} }) record;

  get projectId() {
    return store.state.screen.projectId;
  }
  get deliveryNoteIfo() {
    return [
      {
        name: '送货单编号',
        value: this.formatValue(this.record.logisticsNumber),
      },
      {
        name: '项目名称',
        value: this.formatValue(this.record.projectName),
      },
      {
        name: '供应商',
        value: this.formatValue(this.record.supplier),
      },
      {
        name: '到货日期',
        value:
          this.record.manifestTime > 0
            ? this.$moment(this.record.manifestTime).format('YYYY-MM-DD')
            : '--',
      },
      {
        name: '送货总重量(KG)',
        value: this.formatValue(this.record.manifestTotalWeight, 'KG'),
      },
    ];
  }
  get weighingInfo() {
    return [
      {
        name: '设备名称',
        value: this.formatValue(this.record.deviceName),
      },
      {
        name: '设备码',
        value: this.formatValue(this.record.deviceCode),
      },
      {
        name: '车牌号',
        value: this.formatValue(this.record.carNumber),
      },
      {
        name: '过磅进入时间',
        value:
          this.record.inTime > 0
            ? this.$moment(this.record.inTime).format('YYYY-MM-DD HH:mm:ss')
            : '--',
      },
      {
        name: '过磅进入重量(总重量)',
        value: this.formatValue(this.record.weighingInWeight, 'KG'),
      },
      {
        name: '过磅离开时间',
        value:
          this.record.outTime > 0
            ? this.$moment(this.record.outTime).format('YYYY-MM-DD HH:mm:ss')
            : '--',
      },
      {
        name: '过磅离开重量(毛重）',
        value: this.formatValue(this.record.weighingOutWeight, 'KG'),
      },
      {
        name: '过磅净重量(KG）',
        value: this.formatValue(this.record.weighingNetWeight, 'KG'),
      },
      {
        name: '到货偏差率',
        value: this.formatValue(this.record.deviationRate, '%'),
      },
    ];
  }
  get columns() {
    return [
      {
        title: '物料名称',
        dataIndex: 'materialName',
        key: 'materialName',
      },
      {
        title: '总数量',
        dataIndex: 'materialTotalNum',
        key: 'materialTotalNum',
      },
      {
        title: '总重量',
        dataIndex: 'materialTotalWeight',
        key: 'materialTotalWeight',
      },
      {
        title: '备注',
        dataIndex: 'remark',
        key: 'remark',
      },
    ];
  }
  formatValue(value = '', unit = '') {
    return value === -1 || value === '' ? '--' : value + unit;
  }
}
</script>

<style lang="less" module>
.weighingDetails {
  width: 100%;
  .header {
    margin: 0;
  }
  .infoList,
  .table,
  .image {
    padding: 0.1rem;
  }

  .infoList {
    width: 100%;
    display: flex;
    row-gap: 0.15rem;
    flex-wrap: wrap;
    .infoItem {
      width: 25%;
      display: flex;
      flex-direction: column;
      span {
        font-size: 0.14rem;
      }
      span:first-of-type {
        color: var(--screen-font-2);
      }
      span:last-of-type {
        color: var(--screen-font-3);
      }
    }
  }
  .table {
    .tableTitle {
      font-size: 0.14rem;
      color: var(--screen-font-2);
    }
  }
  .image {
    .imgTitle {
      font-size: 0.14rem;
      color: var(--screen-font-2);
    }
    .imageList {
      display: flex;
      flex-wrap: wrap;
      .imageItem {
        width: 1.3rem;
        height: 1.3rem;
        margin-right: 0.2rem;
        img {
          height: 100%;
          width: 100%;
        }
      }
    }
  }
}
</style>
