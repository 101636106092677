import { request, serviceName } from './base-config';

// 地磅数据统计
export function getWeighingCount(data) {
  return request(`${serviceName}/screen/weighbridge/weighing/count`, {
    method: 'GET',
    body: data,
  });
}

// 过磅数据趋势
export function getWeighingTrend(data) {
  return request(`${serviceName}/screen/weighbridge/weighing/trend`, {
    method: 'GET',
    body: data,
  });
}

// 过磅数据列表
export function getWeighingRecord(data) {
  return request(`${serviceName}/screen/weighbridge/weighing`, {
    method: 'GET',
    body: data,
  });
}

// 送货单过磅分析
export function getWeighingAnalyse(data) {
  return request(`${serviceName}/screen/weighbridge/manifest/weighing`, {
    method: 'GET',
    body: data,
  });
}

// 材料入库情况分析
export function getMaterialWarehousingTrend(data) {
  return request(`${serviceName}/screen/weighbridge/manifest/weighing/trend`, {
    method: 'GET',
    body: data,
  });
}

// 供应商称重偏差排行
export function getDeviationRanking(data) {
  return request(
    `${serviceName}/screen/weighbridge/supplier/weighingDeviation/ranking`,
    {
      method: 'GET',
      body: data,
    },
  );
}

// 材料偏差趋势
export function getDeviationTrend(data) {
  return request(
    `${serviceName}/screen/weighbridge/supplier/weighingDeviation/trend`,
    {
      method: 'GET',
      body: data,
    },
  );
}

// 供应商列表
export function getSupplierList(data) {
  return request(`${serviceName}/screen/weighbridge/supplier`, {
    method: 'GET',
    body: data,
  });
}
