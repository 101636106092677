<template>
  <div :class="$style.wrapper">
    <div style="grid-area: card_1">
      <LayoutCard title="地磅数据统计">
        <div :class="$style.card">
          <BaseWorkerItems :configData="weighingData" />
        </div>
      </LayoutCard>
    </div>
    <div style="grid-area: card_2">
      <LayoutCard title="过磅数据趋势">
        <template #action>
          <LayoutTabs
            style="flex: 6"
            :default-value="0"
            :options="[
              { key: 0, text: '趋势' },
              { key: 1, text: '过磅数据' },
            ]"
            @input="
              idx => {
                activeIndex = idx;
              }
            "
          />
          <a-select
            v-model="selectData.dataOne"
            :options="dayList"
            style="position: absolute; right: 10px"
            @change="getWeighingChartData"
            v-if="activeIndex === 0"
          />
        </template>
        <LineChart
          chart-width="100%"
          chart-height="95%"
          :isSmooth="false"
          symbol="round"
          chartRight="2%"
          chartLeft="4%"
          :unit="['KG']"
          :chart-data="weighingChartData"
          :show-legend="true"
          v-if="activeIndex === 0"
        />
        <ScrollTable
          :columns="weighingColumns"
          :data="weighingList"
          @cellClick="cellClick('1')"
          v-else
        ></ScrollTable>
      </LayoutCard>
    </div>
    <div style="grid-area: card_3">
      <LayoutCard title="送货单过磅分析">
        <template #header>
          <span style="font-size: 0.17rem">送货单过磅分析</span>
          <a
            @click="$refs.weighingRecord.openDetailModal()"
            :class="$style.detail"
            >详情</a
          >
        </template>
        <WeighingRecord ref="weighingRecord" />
      </LayoutCard>
    </div>
    <div style="grid-area: card_4">
      <LayoutCard title="材料入库情况分析">
        <template #action>
          <a-select
            v-model="selectData.dataTwo"
            :options="dayList"
            @change="getWarehouseChartData"
          />
        </template>
        <LineChart
          chart-width="100%"
          chart-height="95%"
          :isSmooth="false"
          symbol="round"
          chartRight="2%"
          chartLeft="4%"
          :unit="['KG']"
          :chart-data="warehouseChartData"
          :show-legend="true"
        />
      </LayoutCard>
    </div>
    <div style="grid-area: card_5">
      <LayoutCard title="供应商称重偏差排行">
        <template #action>
          <a-select
            v-model="selectData.dataThree"
            :options="monthList"
            @change="getDeviationRankList"
          />
        </template>
        <ScrollTable
          :columns="supplierColumns"
          :data="deviationRankList"
          @cellClick="cellClick('2')"
        ></ScrollTable>
      </LayoutCard>
    </div>
    <div style="grid-area: card_6">
      <LayoutCard title="材料偏差趋势">
        <template #action>
          <a-select
            v-model="selectData.dataFour"
            :options="dayList"
            @change="getDeviationChartData"
          />
          <a-select
            v-model="selectData.dataSix"
            @change="getDeviationChartData"
          >
            <a-select-option value=""> 全部 </a-select-option>
            <a-select-option
              :value="item"
              v-for="(item, index) in supplierList"
              :key="index"
            >
              {{ item }}
            </a-select-option>
          </a-select>
        </template>
        <LineChart
          chart-width="100%"
          chart-height="95%"
          :isSmooth="false"
          symbol="round"
          chartRight="2%"
          chartLeft="4%"
          :unit="['%']"
          :chart-data="deviationChartData"
          :show-legend="true"
        />
      </LayoutCard>
    </div>
  </div>
</template>

<script>
import { Vue, Component } from 'vue-property-decorator';
import { LayoutCard, LayoutTabs } from '@/views/screen/layout';
import BaseWorkerItems from '@/views/screen/project/people-management/components/base-worker-items.vue';
import LineChart from '@/views/screen/e-charts/line-chart';
import ScrollTable from '@/views/screen/components/scroll-table.vue';
import WeighingRecord from './components/weighing-record.vue';
import OssImage from '@/views/screen/components/oss-image.vue';
import { createModal } from '@triascloud/x-components';
import WeighingData from './components/weighing-data.vue';
import DeliveryData from './components/delivery-data.vue';
import { PublicSize } from '@/views/green-construction/components/utils';
import {
  getWeighingCount,
  getWeighingTrend,
  getMaterialWarehousingTrend,
  getDeviationTrend,
  getWeighingRecord,
  getDeviationRanking,
  getSupplierList,
} from '@/services/screen/jiangsu/intelligent-weighbridge';

@Component({
  components: {
    LayoutCard,
    LayoutTabs,
    BaseWorkerItems,
    LineChart,
    ScrollTable,
    WeighingRecord,
    OssImage,
  },
})
export default class IntelligentWeighbridgeIndex extends Vue {
  get projectId() {
    return this.$store.state.screen.projectId;
  }
  mounted() {
    this.getSuppliers();
    this.getWeighingData();
    this.getWeighingChartData();
    this.getWarehouseChartData();
    this.getDeviationChartData();
    this.getWeighingList();
    this.getDeviationRankList();
  }

  selectData = {
    dataOne: 'SEVEN_DAY',
    dataTwo: 'SEVEN_DAY',
    dataThree: 'THIRTY_DAY',
    dataFour: 'SEVEN_DAY',
    dataSix: '',
  };
  dayList = [
    { value: 'SEVEN_DAY', label: this.$t('screen.sevenDay') },
    { value: 'THIRTY_DAY', label: this.$t('screen.thirtyDay') },
  ];
  monthList = [
    { value: 'THIRTY_DAY', label: this.$t('screen.thirtyDay') },
    { value: 'SIX_MONTH', label: '最近半年' },
    { value: 'ONE_YEAR', label: '最近一年' },
  ];

  cellClick(type) {
    if (type === '1') {
      createModal(() => <WeighingData />, {
        title: '过磅数据',
        width: PublicSize.LargeLogModalSize,
        closable: true,
        maskClosable: false,
        wrapClassName: 'screen-modal',
      });
    } else {
      createModal(() => <DeliveryData />, {
        title: '送货单过磅数据',
        width: PublicSize.LargeLogModalSize,
        closable: true,
        maskClosable: false,
        wrapClassName: 'screen-modal',
      });
    }
  }

  /** 地磅数据统计 */
  weighingData = [
    {
      title: '今日过磅次数',
      value: 20,
    },
    {
      title: '本月过磅次数',
      value: 166,
    },
    {
      title: '今日过磅重量(KG)',
      value: 166,
    },
    {
      title: '本月过磅重量(KG)',
      value: 122,
    },
  ];
  async getWeighingData() {
    const {
      monthWeighingNum,
      monthWeighingWeight,
      todayWeighingNum,
      todayWeighingWeight,
    } = await getWeighingCount({ projectId: this.projectId });
    this.weighingData[0].value = todayWeighingNum;
    this.weighingData[1].value = monthWeighingNum;
    this.weighingData[2].value = todayWeighingWeight;
    this.weighingData[3].value = monthWeighingWeight;
  }

  /** 过磅数据趋势 */
  activeIndex = 0;
  weighingChartData = [];
  async getWeighingChartData() {
    const params = {
      days: this.selectData.dataOne,
      projectId: this.projectId,
    };
    try {
      const { times, dataList } = await getWeighingTrend(params);
      if (times.length) {
        this.weighingChartData = [
          {
            time: times,
            data: dataList.map(item => ({
              name: item.tag,
              data: item.values.map(e => (e === -1 ? 0 : e)),
            })),
          },
        ];
      } else {
        this.weighingChartData = [];
      }
    } catch {
      return false;
    }
  }

  /** 过磅数据 */
  weighingColumns = [
    {
      title: '设备名称',
      dataIndex: 'deviceName',
      key: 'deviceName',
    },
    {
      title: '车牌号',
      dataIndex: 'plateNumber',
      key: 'plateNumber',
    },
    {
      title: '进入重量(KG)',
      dataIndex: 'inWeight',
      key: 'inWeight',
      ellipsis: true,
    },
    {
      title: '离开重量(KG)',
      dataIndex: 'outWeight',
      key: 'outWeight',
      ellipsis: true,
    },
    {
      title: '过磅图片',
      dataIndex: 'imgs',
      key: 'imgs',
      width: '300px',
      ellipsis: true,
      render: (h, item) => {
        const imgList = item.imgs;
        return imgList.length ? <OssImage ossPath={imgList} /> : '';
      },
    },
  ];
  weighingList = [];
  async getWeighingList() {
    const params = {
      current: 1,
      projectId: this.projectId,
      days: 'THIRTY_DAY',
      size: 100,
    };
    try {
      const { records } = await getWeighingRecord(params);
      this.weighingList = records;
    } catch {
      this.weighingList = [];
    }
  }

  /** 材料入库情况分析 */
  warehouseChartData = [];
  async getWarehouseChartData() {
    const params = {
      days: this.selectData.dataTwo,
      projectId: this.projectId,
    };
    try {
      const { times, dataList } = await getMaterialWarehousingTrend(params);
      if (times.length) {
        this.warehouseChartData = [
          {
            time: times,
            data: dataList.map(item => ({
              name: item.tag,
              data: item.values.map(e => (e === -1 ? 0 : e)),
            })),
          },
        ];
      } else {
        this.warehouseChartData = [];
      }
    } catch {
      return false;
    }
  }

  /** 供应商称重偏差排行 */
  supplierColumns = [
    {
      title: '供应商',
      key: 'supplier',
      dataIndex: 'supplier',
    },
    {
      title: '供货总重(KG)',
      key: 'totalWeight',
      dataIndex: 'totalWeight',
    },
    {
      title: '到货偏差率',
      key: 'deviationRate',
      dataIndex: 'deviationRate',
      format: text => text + '%',
    },
  ];
  deviationRankList = [];
  async getDeviationRankList() {
    const params = {
      current: 1,
      projectId: this.projectId,
      days: this.selectData.dataThree,
      size: 100,
    };
    try {
      const { records } = await getDeviationRanking(params);
      this.deviationRankList = records;
    } catch {
      this.deviationRankList = [];
    }
  }

  /** 材料偏差趋势 */
  deviationChartData = [];
  async getDeviationChartData() {
    const params = {
      days: this.selectData.dataFour,
      projectId: this.projectId,
      supplier: this.selectData.dataSix,
    };
    try {
      const { times, dataList } = await getDeviationTrend(params);
      if (times.length) {
        this.deviationChartData = [
          {
            time: times,
            data: dataList.map(item => ({
              name: item.tag,
              data: item.values.map(e => (e === -1 ? 0 : e)),
            })),
          },
        ];
      } else {
        this.deviationChartData = [];
      }
    } catch {
      return false;
    }
  }

  /** 供应商列表 */
  supplierList = [];
  async getSuppliers() {
    const param = {
      current: 1,
      size: 200,
      projectId: this.projectId,
    };
    const { records } = await getSupplierList(param);
    this.supplierList = records;
  }
}
</script>

<style lang="less" module>
.wrapper {
  height: 100%;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 0.1rem;
  grid-template-rows: 26% 36% 36%;
  grid-template-areas:
    'card_1 card_1 card_1 card_3 card_3 card_3'
    'card_2 card_2 card_2 card_3 card_3 card_3'
    'card_4 card_4 card_5 card_5 card_6 card_6';
  .card {
    padding: 0.08rem;
    height: 100%;
    width: 100%;
  }
  .detail {
    color: #37d7db;
    margin-left: 0.1rem;
    font-size: 0.17rem;
  }
}
</style>
