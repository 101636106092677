/** @name 页面类型枚举 */
export const deviceTypeEnum = {
  /** @name 扬尘噪声监测站 */
  DUST: {
    deviceName: '扬尘噪声监测站',
    deviceGroup: 'dust',
    deviceType: 'DUST_NOISE',
  },
  /** @name 塔吊喷淋 */
  SP: {
    deviceName: '塔吊喷淋',
    deviceGroup: 'sp',
    deviceType: 'SPRAY_CONTROL',
  },
  /** @name 雾炮机 */
  FGM: {
    deviceName: '雾炮机',
    deviceGroup: 'fgm',
    deviceType: 'FOG_GUN_MACHINE',
  },
  /** @name 人脸闸机 */
  FG: {
    deviceName: '人脸闸机',
    deviceGroup: 'fg',
    deviceType: 'FACE_GATE',
  },
  /** @name 智能安全帽 */
  SH: {
    deviceName: '智能安全帽',
    deviceGroup: 'sh',
    deviceType: 'SAFETY_HELMET',
  },
  /** @name 车辆通道 */
  VL: {
    deviceName: '车辆通道',
    deviceGroup: 'vl',
    deviceType: 'VEHICLE_LANE',
  },
  /** @name 车辆清洗监测 */
  VC: {
    deviceName: '车辆清洗监测',
    deviceGroup: 'vc',
    deviceType: 'VEHICLE_CLEANING',
  },
  /** @name 视频监控 */
  VS: {
    deviceName: '视频监控',
    deviceGroup: 'vs',
    deviceType: 'VIDEO_SURVEILLANCE',
  },
  /** @name AI视频分析系统 */
  AIV: {
    deviceName: 'AI视频分析系统',
    deviceGroup: 'aiv',
    deviceType: 'AI_VIDEO',
  },
  /** @name 智能临边防护网监测 */
  PN: {
    deviceName: '智能临边防护网监测',
    deviceGroup: 'pn',
    deviceType: 'PROTECTION_NET',
  },
  /** @name 塔吊安全监测 */
  TCSF: {
    deviceName: '塔吊安全监测',
    deviceGroup: 'tcsf',
    deviceType: 'TOWER_CRANE_SAFETY',
  },
  /** @name 升降机监测 */
  ED: {
    deviceName: '升降机监测',
    deviceGroup: 'ed',
    deviceType: 'ELEVATOR_DETECTOR',
  },
  /** @name 吊篮监测 */
  HBD: {
    deviceName: '吊篮监测',
    deviceGroup: 'hbd',
    deviceType: 'HANGING_BASKET_DETECTOR',
  },
  /** @name 卸料平台检测 */
  UP: {
    deviceName: '卸料平台检测',
    deviceGroup: 'up',
    deviceType: 'UNLOADING_PLATFORM',
  },
  /** @name 高支模检测 */
  HF: {
    deviceName: '高支模检测',
    deviceGroup: 'hf',
    deviceType: 'HIGH_FORMWORK',
  },
  /** @name 深基坑监测 */
  DFP: {
    deviceName: '深基坑监测',
    deviceGroup: 'dfp',
    deviceType: 'DEEP_FOUNDATION_PIT',
  },
  /** @name 混凝土检测 */
  CT: {
    deviceName: '混凝土检测',
    deviceGroup: 'ct',
    deviceType: 'CONCRETE_TESTING',
  },
  /** @name 智能烟感 */
  SMOKE: {
    deviceName: '智能烟感',
    deviceGroup: 'smoke',
    deviceType: 'SMOKE_DETECTOR',
  },
  /** @name 螺丝松动检测 */
  SCREW: {
    deviceName: '螺丝松动检测',
    deviceGroup: 'screw',
    deviceType: 'SCREW_DETECTOR',
  },
  /** @name 智能水表 */
  WM: {
    deviceName: '智能水表',
    deviceGroup: 'wm',
    deviceType: 'WATER_METER',
  },
  /** @name 智能电表 */
  EM: {
    deviceName: '智能电表',
    deviceGroup: 'em',
    deviceType: 'ELECTRICITY_METER',
  },
  /** @name 污水监测 */
  SD: {
    deviceName: '污水监测',
    deviceGroup: 'sd',
    deviceType: 'SEWAGE_DETECTOR',
  },
  /** @name 显示屏 */
  DIS: {
    deviceName: '显示屏',
    deviceGroup: 'dis',
    deviceType: 'DISPLAY',
  },
  /** @name 工地广播 */
  BRO: {
    deviceName: '工地广播',
    deviceGroup: 'bro',
    deviceType: 'BROADCAST',
  },
  /** @name 智能地磅 */
  WB: {
    deviceName: '智能地磅',
    deviceGroup: 'wb',
    deviceType: 'WEIGHT_BRIDGE',
  },
  /** @name 智能入侵监测 */
  ID: {
    deviceName: '智能入侵监测',
    deviceGroup: 'id',
    deviceType: 'INTRUSION_DETECTOR',
  },
  /** @name 标养室监测 */
  SCR: {
    deviceName: '标养室监测',
    deviceGroup: 'scr',
    deviceType: 'STANDARD_CURING_ROOM',
  },
};
