import request from '../request';

const serviceName = '/smart-site/device';

// 获取设备列表
export function deviceList(data, deviceGroup) {
  return request(`${serviceName}/${deviceGroup}/search/list`, {
    method: 'POST',
    body: data,
  });
}
// 通过项目ID获取设备列表
export function deviceListById(projectId, deviceGroup) {
  return request(`${serviceName}/${deviceGroup}/device/list/${projectId}`, {
    method: 'GET',
  });
}
// 添加设备
export function addDevice(data, deviceGroup) {
  return request(`${serviceName}/${deviceGroup}/add`, {
    method: 'POST',
    body: data,
  });
}
// 编辑设备
export function modifyDevice(data, deviceGroup) {
  return request(`${serviceName}/${deviceGroup}/edit`, {
    method: 'POST',
    body: data,
  });
}
// 删除设备
export function deleteDeviceById(deviceId, deviceGroup) {
  return request(`${serviceName}/${deviceGroup}/delete/${deviceId}`, {
    method: 'DELETE',
  });
}
// 获取单个设备信息
export function deviceInfo(deviceId, deviceGroup) {
  return request(`${serviceName}/${deviceGroup}/info/${deviceId}`, {
    method: 'GET',
  });
}
// 获取项目列表
export function projectList() {
  return request('/smart-site/project/list', {
    method: 'GET',
  });
}
// 获取设备在线/离线数量
export function deviceStatusCount(data, deviceGroup) {
  return request(`${serviceName}/${deviceGroup}/search/online/count`, {
    method: 'POST',
    body: data,
  });
}
// 获取设备警报列表
export function deviceAlarmList(data, deviceGroup) {
  return request(`${serviceName}/${deviceGroup}/alarm/search/list`, {
    method: 'POST',
    body: data,
  });
}
// 手动添加警报
export function addDeviceAlarm(data, deviceGroup) {
  return request(`${serviceName}/${deviceGroup}/alarm/add`, {
    method: 'POST',
    body: data,
  });
}
// 删除警报
export function delDeviceAlarm(deviceId, deviceGroup) {
  return request(`${serviceName}/${deviceGroup}/alarm/delete/${deviceId}`, {
    method: 'DELETE',
  });
}

// 获取平面图配置
export function getPlanData(projectId, deviceGroup) {
  return request(`${serviceName}/${deviceGroup}/plan?projectId=${projectId}`, {
    method: 'GET',
  });
}

// 设置平面图配置
export function setPlanData(data, deviceGroup) {
  return request(`${serviceName}/${deviceGroup}/plan`, {
    method: 'POST',
    body: data,
  });
}

// 获取抓拍记录分页
export function getSnapRecord(data, deviceGroup) {
  return request(`${serviceName}/${deviceGroup}/record`, {
    method: 'GET',
    body: data,
  });
}

// 获取摄像头分页
export function getCameraRecord(data, deviceGroup) {
  return request(`${serviceName}/${deviceGroup}/cameraPage`, {
    method: 'GET',
    body: data,
  });
}

// 修改摄像头名称
export function setCamera(data, deviceGroup) {
  return request(`${serviceName}/${deviceGroup}/camera`, {
    method: 'PUT',
    body: data,
  });
}

// 删除摄像头
export function delCamera(data, deviceGroup) {
  return request(`${serviceName}/${deviceGroup}/camera`, {
    method: 'DELETE',
    body: data,
  });
}
